@import "../variables";

/* SELECTOR ------------------------------ */

.page-content {
  display: flex;
  height: 100%;

  .contentEditor {
    flex-grow: 1;

    .content-header {
        display: flex;
        justify-content: space-between;

        .panel {

            margin: 0;
            margin-left:8px;
            padding: 0.275rem 1rem !important;

        }
    }

    h2 {
        color: $highlightColorGreen;
        text-transform: uppercase;
    }

    .editor {

        padding: 1rem;
        font-size: 1rem;

        label {
            margin-right: 1rem;

            input {
                margin-left: 0.5rem;
            }
        }

    }

    .block-dropzone {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px dashed $highlightColorGreen;
        color: $highlightColorGreen;
        margin-left: 150px;

        form {
            margin: 1rem;
        }

        label {
            display: block;
            font-size: 1.25rem;
            color: $highlightColorGreen;
            margin: 0 0 0.5rem 0;
            text-align: center;
        }

        select {
            background-color: #000;
            min-width: 260px;
            margin-right: 1rem;
        }
    }
  }

  > .panel {
      min-width: 230px;

      +.panel {
          margin-left: 10px;
      }
  }

  ul {
      padding: 0;
      margin-top: 10px;
      margin-bottom: 0;

      li {
          list-style: none;
          
          div {
            background-color: $highlightColorGreen;
            margin-bottom: 4px;
          }
      }
  }
}

.countdown {
margin-top:7px;
}
