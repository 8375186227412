    
    #content #login {
    display:table;        
    position:absolute;
    left:50%;
    top:20%;
    max-width:300px;
    margin-left:-150px;
    text-align:center;
    padding:30px 30px;    
    }

    #login form {
    padding-top:20px;
    text-align:left;
    }

    #login input[type=text], #login input[type=password] {
    margin-bottom:20px;
    width:100%;
    }

    #logout {
    position:absolute;
    top:5px;
    right:5px;
    }

    #loginFeedback {
    text-align:center;
    }