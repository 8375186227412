@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import "variables";

//------

  * {  
  -webkit-tap-highlight-color: transparent;     
  }

  html,
  body {
  margin:0;
  padding:0;
  }

  html {
  box-sizing:border-box;
  height:calc( 100% - 10px );
  }

  body {
  height:100%;
  box-sizing:border-box;    
  margin: 0;
  padding: 0;    
  font-family:$mainFont;
  font-weight:100;
  font-size:$fontSizeMedium;  
  background-color:$backgroundColor;
  color:white;
  }

  #root {
  height:100%;
  }
  
  h1, h2, h3, label, font.label {
  margin:0; 
  color:$headlineColor;                    
  font-weight:100;
  letter-spacing:$letterSpacing;
  }

  label {
  margin-left:3px;  
  }

  h2 {
  font-size:$fontSizeLarge;
  margin-left:$h2Margin;
  }
  
  hr {
  border:0;  
  height:1px;
  padding:0;
  margin: 10px 0 2px 0 ;
  background-color:#888;
  }

  .panel,
  .button {

    background-color:$panelColor;
    border:1px solid $panelBorderColor;

    -webkit-box-shadow: inset 0px 1px 1px 0px rgba(255,255,255,0.1);
    -moz-box-shadow: inset 0px 1px 1px 0px rgba(255,255,255,0.1);
    box-shadow: inset 0px 1px 1px 0px rgba(255,255,255,0.1);

  }

  .panel.light,
  .button.light {
  background-color:$panelColorLight;
  }

  .panel.extraLight,
  .button.extraLight {
  background-color:$panelColorExtraLight;
  }

  .panel.highlighted,
  .button.highlighted {
  background-color:$highlightColorGreen;
  }

  input {
    font-family: $mainFont;
  }

  input[type=text],
  input[type=password],
  input[type=number],
  input[type=datetime-local],
	textarea {
  
    box-sizing:border-box;    
    margin-top:4px;
    background-color:black;
    border:none;
    padding:7px;
    border:1px solid #161616;

    font-family:$mainFont;
    letter-spacing:0.05em;
    color:white;

    -webkit-box-shadow: inset 0px 2px 2px 0px rgba(38,38,38,1);
    -moz-box-shadow: inset 0px 2px 2px 0px rgba(38,38,38,1);
    box-shadow: inset 0px 2px 2px 0px rgba(38,38,38,1);

  }

  input[type=datetime-local] {
    color: #000;
    background-color: #fff;
  }
  
  input[type=button] {
  cursor:pointer;
  }

  input[type=text]:focus,
  input[type=password]:focus,
  input[type=number]:focus,
  input[type=submit]:focus,
  input[type=button]:focus,
  input[type=datetime-local]:focus {
  outline:0;
  border:1px solid $highlightColorRed;
  }  

  input[type=button]:focus {
  outline:0;
  }  

  .disabled input[type=text],
  .disabled input[type=password],
  .disabled input[type=number],
  .disabled input[type=submit],
  .disabled textarea {
  background-color:$disabledColor;
  opacity:$disabledOpacity;
  cursor:default;
  }

  .disabled select,
  .disabled input[type=datetime-local],
  input[type=datetime-local]:disabled {
  opacity:$disabledOpacity;    
  cursor:default;
  }

  .buttonList {
  list-style-type:none;
  padding:0;
  margin:0;
  margin-top:10px;
  }

  .buttonList li {
  margin-bottom:4px;
  }

  .buttonList a,
  .buttonList input[type=button] {
  display:block;
  box-sizing:border-box;  
  text-decoration:none;
  color:white;
  padding: 5px 10px;
  width:100%;
  margin:0;
  text-align:left;
  font-size:$fontSizeMedium;
  font-family:$mainFont;
  letter-spacing:$letterSpacing;
  cursor:pointer;
  }

  .buttonList input[type=button]:disabled,
  .buttonList.horizontal input[type=button]:disabled {
  background-color:$disabledButtonColor;
  opacity:$disabledOpacity;
  cursor:default;
  }  

  .buttonList a.active,
  .buttonList input[type=button].active {
  background-color:$highlightColorRed;
  }

  .buttonList.horizontal a,
  .buttonList.horizontal input[type=button] {
  display:inline-block;
  width:100px;
  text-align:center;
  }

  .buttonList.horizontal>*:not(:first-child) {
  margin-left:8px;
  }

  .disabled input[type=button] {
  opacity:$disabledOpacity;
  cursor:default;
  }

  select {
    
    padding:7px;    
    background-color:#3e3e3e;
    border:1px solid $panelBorderColor;
    color:white;

    -webkit-box-shadow: inset 0px 1px 1px 0px rgba(255,255,255,0.1);
    -moz-box-shadow: inset 0px 1px 1px 0px rgba(255,255,255,0.1);
    box-shadow: inset 0px 1px 1px 0px rgba(255,255,255,0.1);

    /*
    -webkit-appearance: none;
    -moz-appearance: none;
    */

  }

  select:focus {
  outline:0;
  border:1px solid $highlightColorRed;
  }    

/* LOGIN ---------------------------------- */

  #login input[type=button] {
  margin-top:10px;
  width:50%;
  margin-left:25%;
  padding:6px;
  padding-top:8px;
  color:white;
  cursor:pointer;
  }

  #login input[type=button]:active {
  background-color:$highlightColorRed;
  }
  
  #loginFeedback {
  color:$highlightColorRed;
  margin-bottom:15px;
  }

  #loginFeedback:empty {
  display:none;
  }  
  
/* NAVIGATION -------------------------- */

  #navigation {
  padding: 6px;    
  min-width:$panelMinWidth;  
  box-sizing:border-box;
  }

/* PANEL LAYOUT ------------------------ */

  #header {
  box-sizing:border-box;
  padding: 10px 6px ;
  margin:$panelMargin; 
  margin-bottom:0px;
  height:$headerHeight;
  }

  #header img {
  margin-left:$h2Margin;
  }

  #content {
  display:flex;  
  padding-top:0;
  padding:$panelMargin;
  min-height:calc( 100% - #{ $headerHeight + $panelMargin*2 } );
  }

  #content>div {    
  min-width:$panelMinWidth;     
  margin-right:0;
  margin-left:$panelMargin;
  box-sizing:border-box;
  }

  #content .panel {
  padding:6px;
  }

  #content>div:last-child {
  flex-grow:1;      
  }

/* CONTENT EDITOR ---------------------- */

  .contentEditorButtons {
  position:relative;
  text-align:right;
  margin-top:-16px;
  margin-right:2px;
  }

  .contentEditorButtons input[type=button] {
  font-size:$fontSizeMedium;
  }

  .contentEditor .panel {
  color:white;
  padding:8px;
  margin-top:8px;
  }

  .contentEditor label, .contentEditor font.label {
  color:white;
  }

  .contentEditorButtons .loading {
  visibility:hidden;
  position:absolute;
  margin-top:3px;
  margin-left:-28px;  
  }

  .disabled .contentEditorButtons .loading {
  visibility:visible;
  }

/* DEBUG -------------------------------- */

  #debug {
  position:fixed;
  top:10px;
  right:10px;
  z-index:100;
  background-color:white;
  padding:5px;
  }