    @import "../variables";

    $defaultColor: #555555;
    $checkedColor: #57b66e;
    $speed: 0.2s;
    $border: 1px solid rgba(0,0,0,0.85);

    .toggle {
    position: relative;
    display: inline !important;
    top:3px;    
    width: 60px;
    min-height:auto;    
    }

    .toggle font.label {
    }

    .toggle span {        
    min-height:auto !important;
    display:inline-block !important;
    height:18px;
    width:35px;
    border:$border;
    box-sizing:border-box;            
    -webkit-box-shadow: inset 0px 1px 1px 0px rgba(255,255,255,0.2);
    -moz-box-shadow: inset 0px 1px 1px 0px rgba(255,255,255,0.2);
    box-shadow: inset 0px 1px 1px 0px rgba(255,255,255,0.2);    
    }

    .toggle input { 
    opacity: 0;
    width: 0;
    height: 0;
    }

    .toggle .slider {
    position: relative;
    cursor: pointer;
    top: 6px;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top:0;
    margin-bottom:0 !important;
    min-height:auto;
    background-color: $defaultColor;
    -webkit-transition: $speed;
    transition: $speed;
    -webkit-box-shadow: inset 0px 1px 1px 0px rgba(255,255,255,0.2);
    -moz-box-shadow: inset 0px 1px 1px 0px rgba(255,255,255,0.2);
    box-shadow: inset 0px 1px 1px 0px rgba(255,255,255,0.2);      
    }

    .toggle .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    top:1px;
    left: 3px;
    bottom: 2px;
    background-color: $defaultColor;
    -webkit-transition: $speed;
    transition: $speed;
    -webkit-box-shadow: inset 0px 1px 1px 0px rgba(255,255,255,0.2);
    -moz-box-shadow: inset 0px 1px 1px 0px rgba(255,255,255,0.2);
    box-shadow: inset 0px 1px 1px 0px rgba(255,255,255,0.2);      
    }

    .toggle input + .slider:before {
    border:$border;
    box-sizing:border-box; 
    -webkit-box-shadow: inset 0px 1px 1px 0px rgba(255,255,255,0.2);
    -moz-box-shadow: inset 0px 1px 1px 0px rgba(255,255,255,0.2);
    box-shadow: inset 0px 1px 1px 0px rgba(255,255,255,0.2);             
    }

    .toggle input:checked + .slider {
    background-color: $checkedColor;
    }

    .toggle input:focus + .slider {
    -webkit-box-shadow: inset 0px 1px 1px 0px rgba(255,255,255,0.2);
    -moz-box-shadow: inset 0px 1px 1px 0px rgba(255,255,255,0.2);
    box-shadow: inset 0px 1px 1px 0px rgba(255,255,255,0.2);        
    }

    .toggle input:checked + .slider:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);    
    }

    /* Rounded sliders */
    .toggle .slider.round {
    border-radius: 34px;
    }

    .toggle .slider.round:before {
    border-radius: 50%;
    }

    .disabled .toggle .slider {
    opacity:$disabledOpacity;
    cursor:wait;
    }