@import "../variables";

.playlistItem-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.pointer-container {

  position:relative;
  width: 150px;

  .pointer {
    outline: 0;
    width: 80%;
    border-width: 1px;
    background-color: #505050;
    color: #fff;
    border-color: #161616;
    font-size: 1rem;
    margin: 0 10%;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
 
    &.active {
      
      background-color: $highlightColorGreen;
      cursor: default;

      width: 65%;
      padding-left:13.5%;
      
    }
  }

  .arrow {

    position:absolute;
    left:calc( 65% + 15px );
    top:0px;
    height: 0;
    width: 0;
    border-left: 19px solid #57b66e;
    border-right: 19px solid transparent;
    border-bottom: 19px solid transparent;
    border-top: 19px solid transparent;

  }

}

.playlist-item {
  flex-grow: 1;

  &.panel {
    margin-top: 0;
  }
}

.controls {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;

  .duration-form {
    display: flex;
    align-items: center;
    font-size: 1rem;
  
    .duration-input {
      margin-left: 0.5rem;
    }
  }
}
