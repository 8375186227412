@import "../variables";

.secretKeyInput {
  width: 500px;
}

#users {
}

#users > div {
  margin-bottom: 0.5rem;
  margin-right: 10px;
}

#users div {
  margin-bottom: 10px;
}

#users ul {
  padding: 0;
  margin: 0;
  border-collapse: collapse;
}

#users li {
  list-style-type: none;
  display: table-row;
  border-bottom: 1px solid #161616;
  cursor: pointer;
}

#users li input[type=button] {

  /*
  text-decoration:underline;
  background:none;
  border:none;
  color:#888;
  */

  padding:2px 5px;

}

#users li:last-child {
  border-bottom: none;
}

#users .title {
  color:$headlineColor;
}

#users ul span {
  display: table-cell;
  padding: 5px 10px;
}

#users ul span {
  border-right: 1px solid #161616;
}

#users ul span:last-child {
  border-right: none;
}

#users p {
  text-align: center;
  margin: 0;
}

#users p.red {
  color: $highlightColorRed;
}

#users p.green {
  color: $highlightColorGreen;
}

#users div {
  display: block;
  margin-right: 0 !important;
}

#settings label,
#users label {
  color:white;
  display:block;
}

#settings input[type="text"] {
  margin-bottom:10px;
}

#settings input[type="button"],
#users input[type="button"] {
color:white;
}

#users p {
  text-align:left;
  padding:10px;
}

.errorMessage {
  color:$errorColor;
}

.userEditor input[type=text] {
  margin:5px;
  padding:2px 5px;
  box-shadow:none;
  min-width:200px;
  box-sizing:border-box;
  min-height:24px;
}

.userEditor input[type=text]:disabled {
  background:none;
  border:none;
}

.tooltip {
  position:"relative";
  display:inline-block;
  background-color:$highlightColorGreen;
  border-radius:20px;
  min-width:15px;
  text-align:center;
  cursor:help;
}

.tooltipContent {
  display:block;
  position:absolute;
  margin-left:-10px;
  margin-top:-28px;
  padding:10px !important;
  text-align:left;
  padding-left:50px !important;
}
