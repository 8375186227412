$mainFont: "Open Sans", sans-serif;

// general styles ---

$headerHeight: 75px;
$panelMinWidth:230px;
$panelMargin:10px;
$h2Margin:2px;
$letterSpacing:0.11em;

$fontSizeSmall:10px;
$fontSizeMedium:12px;
$fontSizeLarge:1.25rem;

// colors ---

$backgroundColor:#202020;
$panelColor:#272727;
$panelBorderColor:#161616;

$panelColorLight:#363636;
$panelColorExtraLight:#505050;

$headlineColor: #ee4457;
$highlightColorRed: #ee4457;
$highlightColorGreen: #57b66e;

$disabledColor:#111;
$disabledOpacity:0.6;

$disabledButtonColor:#333;

$errorColor: #ee4457;
