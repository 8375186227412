ul.draggable {

    display:flex;
    flex-direction:column;
    position:relative;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

}

ul.draggable {
margin-top:0;
}

ul.draggable li div {
background-color:transparent;
}

ul.draggable li {

    width:100%;
    border:auto;
    box-sizing:border-box;
    cursor:grab;

}

ul.draggable li.selected {

    //margin-left:-0.5rem;
    //margin-top:-0.5rem;
    cursor:grabbing;
    z-index:100;

}

/*
ul.draggable li.selected .playlist-item {

    $x: 0.2rem;
    $y: 0.2rem;
    $blur: 0.2rem;
    $opacity: 0.2;

    -webkit-box-shadow: $x $y $blur 0px rgba(0,0,0,$opacity);
    -moz-box-shadow: $x $y $blur 0px rgba(0,0,0,$opacity);
    box-shadow: $x $y $blur 0px rgba(0,0,0,$opacity);

}
*/