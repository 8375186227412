  @import "../variables";

/* SELECTOR ------------------------------ */

  #blockSelector .newBlockButton {
  text-align:center;
  }

/* EDITOR ------------------------------ */

  /*
  #blockEditor h2 {
  color:white;
  }
  */

  #blocks {
  display:flex;
  padding-top:0;
  height:100%;
  }

  #blocks > div {
  min-width:$panelMinWidth;     
  }

  #blocks > div:not(:last-child) {
  margin-right:$panelMargin;  
  }

  #blocks > div:last-child { 
  flex-grow:1;
  }

  #blockEditor .nameAndTypeEditor {
  }

  #blockEditor .nameEditor,
  #blockEditor .typeEditor {
  display:inline-table;
  width:300px;
  box-sizing:border-box;
  }

  #blockEditor .nameEditor {
  padding-left:5px;
  padding-right:15px;
  }

  #blockEditor .typeEditor {
  padding-left:15px;
  padding-right:10px;
  }

  #blockEditor .typeEditor select {
  width:100%;
  text-transform:capitalize;
  margin-top:4px;
  }  

  #blockEditor .typeEditor select option {
  text-transform:capitalize;
  }

  #blockEditor input[type=text],
  #blockEditor input[type=number],
  #blockEditor textarea {
  display:table-cell;
  width:100%;
  }

  #blockEditor select {
  display:table-cell;
  min-width:120px;
  }  

  #blockEditor .panel .header {
  position:relative;
  padding: 0 5px;
  }

  #blockEditor .panel .header h3 {
  color:white;
  position:relative;
  top:3px;
  height:24px;
  }

  #blockEditor .panel .header .minimizeButton {
  
    position:absolute;
    top:0px;
    right:0px;

    border:none;

    width:24px;
    height:24px;
    padding:0;

    background-color:rgba(1, 1, 1, 0);

  }

  #blockEditor .panel.minimized .header .minimizeButton {
  background-image: url(/icon_maximize.png);    
  }

  #blockEditor .panel.maximized .header .minimizeButton {
  background-image: url(/icon_minimize.png);    
  }

  #blockEditor form {
  margin-top:8px;
  padding: 0 5px;
  }

  #blockEditor form label {
  padding-top:3px;
  width:100%;
  }

  #blockEditor textarea {
  min-height:147px;
  }

  #blockEditor ul {
  padding: 0;
  }

  #blockEditor ul li {
  list-style-type:none;
  }

/* FORM ------------------------------ */

  #blockEditor .panel.minimized form {
  display:none;
  }

  #blockEditor form .section {
  display:flex;
  }

  #blockEditor form span {
  display:block;
  min-height:32px;
  margin-bottom:10px;
  }

  #blockEditor form .section .column {
  flex-grow:1;
  width:50%;
  padding-bottom:0;
  }  

  #blockEditor form .section .column:last-child {
  padding-left:10px;
  }

  #blockEditor form ul {
  list-style-type:none;
  padding:0;
  margin:0;
  }

/* OPTIONS ------------------------------ */

  #blockEditor .optionEditor {
  position:relative;
  background-color:#3e3e3e;
  margin-bottom:10px;
  }

  #blockEditor .optionEditor h2 {
  color:white;
  }

  #blockEditor .optionEditor input[type=button] {
  padding:0;
  }

  #blockEditor .addItemButton {

    position:absolute;
    right:7px;
    top:8px;

    width:24px;
    height:24px;
    padding:0;
    background:none;    
    background-image:url("/icon_add.png");  
    border:none;

  }

  #blockEditor.disabled .addItemButton {
  opacity:$disabledOpacity;
  cursor:wait;
  }  

  #blockEditor .optionList {

    /*
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    */

    margin-top:10px;
    margin:5px;

  }

  #blockEditor .optionList li {
  margin-bottom:15px;      
  display:inline-block;
  width:100%;
  }

  #blockEditor .optionList li span {
  min-height:auto;
  }

  #blockEditor .optionList li .optionTitle, 
  #blockEditor .optionList li .optionTextInput,
  #blockEditor .optionList li .removeButton {
  display:inline-block;
  }

  #blockEditor .optionList li .optionTitle {
  margin-right:10px;
  }

  #blockEditor .optionList li .removeButton { 
  position:relative;
  top:7px;
  margin-left:8px;
  width:24px;
  height:24px;
  background:none;
  background-image:url("/icon_remove.png");  
  border:none;
  }

  #blockEditor.disabled .optionList li .removeButton {
  opacity:$disabledOpacity;
  cursor:wait;
  }

  #blockEditor .optionList li .optionTextInput {
  width:calc( 100% - 100px );
  }

  #blockEditor .optionList li .correctAnswerToggle {
  display:block;
  margin-left:calc( 100% - 145px );
  }

  #blockEditor .optionList li .correctAnswerToggle label,
  #blockEditor .optionList li .correctAnswerToggle input {
  display:inline-block;
  }