    
    .questionEditor .controllers {
    position:relative;
    height:35px;
    }

    .questionEditor .controllers input[type=button] {
    position:absolute;
    right:0px; 
    color:white;
    padding:5px 10px;    
    }

